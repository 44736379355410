import RoomModal from "./RoomModal";
import AddRoomButton from "./AddRoomButton";
import {mapState, mapActions, mapGetters} from 'vuex'
import Vue from "vue/dist/vue"


export default Vue.component('RoomCollection', {
    /**
     * Vue components inside this one.
     *
     * @property {object}
     */
    components: {
        /** Access this child component with this.$refs.modal. */
        RoomModal,
        AddRoomButton
    },

    /**
     * All computed properties of the component.
     *
     * @property {object}
     */
    computed: {
        /*
         * Import properties of states:
         *
         * ...mapState(['namespace/property'])
         * ...mapState('namespace', ['property', 'property'])
         * ...mapState({
         *     propertyAlias: state => state.namespace.property,
         *     propertyAlias: state => state.namespace.property
         * })
         *
         * Then use them:
         * - in Vue templates: {{ property }} or {{ propertyAlias }}
         * - in here: this.property or this.propertyAlias
         *
         * Import getters of modules:
         *
         * ...mapGetters(['namespace/getter'])
         * ...mapGetters('namespace', ['getter', 'getter'])
         * ...mapGetters({
         *     getterAlias: 'namespace/getter',
         *     getterAlias: 'namespace/getter'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ getter() }} or {{ getterAlias() }}
         * - in here: this.getter() or this.getterAlias()
         */
        ...mapState({
            registration: state => state.registration,
            participants: state => state.participantCollection.all,
            rooms: state => state.roomCollection.all,
            wantRooming: state => state.roomCollection.wantRooming
        }),
        ...mapGetters({
            // From module participants.
            hasParticipants: 'participantCollection/hasParticipants',
            hasParticipantsWithRoomingPack: 'participantCollection/hasParticipantsWithRoomingPack',
            getParticipantByUidx: 'participantCollection/getParticipantByUidx',
            getParticipantCompleteName: 'participantCollection/getCompleteName',
            areAllParticipantsInRoom: 'participantCollection/areAllParticipantsInRoom',

            // From module roomTypes.
            findRoomTypeById: 'roomTypes/findById',
            getRoomTypeLabel: 'roomTypes/getLabel',

            // From module hotels.
            hasMoreThanOneHotel: 'hotels/hasMoreThanOne',
            findHotelById: 'hotels/findById',
            getHotelLabel: 'hotels/getLabel',
        })
    },

    /**
     * All methods of the component.
     *
     * @property {object}
     */
    methods: {
        /*
         * Import actions of modules:
         *
         * ...mapActions(['namespace/action'])
         * ...mapActions('namespace', ['action', 'action'])
         * ...mapActions({
         *     actionAlias: 'namespace/action',
         *     actionAlias: 'namespace/action'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ action() }} or {{ actionAlias() }}
         * - in here: this.action() or this.actionAlias()
         *
         * Import mutations of modules:
         *
         * ...mapMutations(['namespace/mutation'])
         * ...mapMutations('namespace', ['mutation', 'mutation'])
         * ...mapMutations({
         *     mutationAlias: 'namespace/mutation',
         *     mutationAlias: 'namespace/mutation'
         * })
         *
         * Then use them:
         * - in Vue templates: {{ mutation() }} or {{ mutationAlias() }}
         * - in here: this.mutation() or this.mutationAlias()
         */
        ...mapActions('roomCollection', ['remove', 'updateProp']),

        /**
         * Tell whether the rooming section is displayed.
         *
         * @returns {boolean}
         */
        isDisplayed() {
            const
                isParticipating = this.registration.isParticipating,
                hasParticipants = this.hasParticipants,
                hasParticipantsWithRoomingPack = this.hasParticipantsWithRoomingPack
            ;

            return (isParticipating && hasParticipants && hasParticipantsWithRoomingPack);
        },

        /**
         * Tell whether a room can be added.
         *
         * @returns {boolean}
         */
        canAddRoom() {
            return this.wantRooming;
        },

        /**
         * Open the room-creation modal with empty data.
         *
         * @returns {void}
         */
        createRoom() {
            this.$refs.modal.open();
        },
        /**
         * Open the room-creation modal with prefilled data.
         * @param room
         */
        editRoom(room) {
            this.$refs.modal.open(room);
        },

        updateState(prop, event) {
            let value = event.target.value;

            if (prop === 'wantRooming') {
                value = (value === '1');
            }

            this.updateProp({property: prop, value: value});
        }
    },

    /**
     * Executed when each time the component is created.
     *
     * @method
     * @returns {void}
     */
    created() {
        // this.$store.dispatch('roomCollection/load');
    }
})

