var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
      ],
      staticClass: "overlay-modal",
      on: { click: _vm.close },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        staticClass: "room-modal",
      },
      [
        _c("div", { staticClass: "room-modal-header" }, [
          _c("div", { staticClass: "room-modal-fixed-title" }, [
            _c("h4", { staticClass: "u-txt-uppercase" }, [
              _vm._v(_vm._s(_vm._f("trans")("form.rooming.modal.title"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "room-modal-close",
              attrs: { type: "button" },
              on: { click: _vm.close },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    fill: "none",
                    height: "33",
                    viewBox: "0 0 24 24",
                    width: "33",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "m16 8-8 8m.00001-8 7.99999 8",
                      stroke: "#000",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "room-modal-content" },
          [
            _vm.hasMoreThanOneHotel
              ? _c("p", {
                  staticClass: "help",
                  domProps: {
                    innerHTML: _vm._f("trans")(
                      "form.rooming.modal.help-multiple"
                    ),
                  },
                })
              : _c("p", {
                  staticClass: "help",
                  domProps: {
                    innerHTML: _vm._f("trans")("form.rooming.modal.help"),
                  },
                }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "select-label" }, [
              _c("label", [
                _vm._v(
                  _vm._s(_vm._f("trans")("form.rooming.occupants")) + " :"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                multiple: "",
                placeholder: _vm._f("trans")(
                  "form.rooming.modal.occupants.select.placeholder"
                ),
                options: _vm.getSelectableParticipants(),
                selectable: _vm.isSelectable,
                value: _vm.occupants,
                label: "_vSelectLabel",
              },
              on: {
                "option:selecting": _vm.selectParticipant,
                "option:deselecting": _vm.deselectParticipant,
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function (option) {
                    return [_vm._v(_vm._s(_vm.getCompleteName(option)))]
                  },
                },
                {
                  key: "no-options",
                  fn: function () {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")(
                            "form.rooming.modal.occupants.select.noMoreOccupant"
                          )
                        )
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _vm.hasMoreThanOneHotel
              ? _c("div", { staticClass: "room-modal-hotels" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm._f("trans")("form.rooming.modal.hotel.label"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      attrs: { name: "hotel" },
                      on: { change: _vm.selectHotel },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { disabled: "" },
                          domProps: { selected: _vm.hotel === null },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")(
                                "form.rooming.modal.hotel.placeholder"
                              )
                            ) + "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.hotels, function (hotelChoice) {
                        return _c(
                          "option",
                          {
                            attrs: {
                              disabled: _vm.hotelIsDisabled(hotelChoice),
                            },
                            domProps: {
                              value: hotelChoice.id,
                              selected:
                                _vm.hotel && hotelChoice.id === _vm.hotel.id,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getHotelLabel(hotelChoice)) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "room-form" }, [
              _vm.roomTypes
                ? _c(
                    "div",
                    { staticClass: "form-item typeOfRoom" },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "form.rooming.modal.room-type.label"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.roomTypes, function (roomTypeChoice) {
                        return _c("div", [
                          _c("div", { staticClass: "form-item-choice" }, [
                            _c("input", {
                              attrs: {
                                type: "radio",
                                name: "room-type",
                                id: "room-type" + roomTypeChoice.id,
                                disabled:
                                  _vm.isRoomTypeDisabled(roomTypeChoice),
                              },
                              domProps: {
                                value: roomTypeChoice.id,
                                checked:
                                  _vm.roomType &&
                                  roomTypeChoice.id === _vm.roomType.id,
                              },
                              on: { change: _vm.selectRoomType },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { for: "room-type" + roomTypeChoice.id },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getRoomTypeLabel(roomTypeChoice, {
                                        withDetails: true,
                                      })
                                    ) +
                                    "\n\n                "
                                ),
                                _vm.availabilities(
                                  _vm.hotel.id,
                                  roomTypeChoice.id
                                ) !== null
                                  ? _c("span", [
                                      _vm.availabilities(
                                        _vm.hotel.id,
                                        roomTypeChoice.id
                                      ) > 0
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                      " +
                                                _vm._s(
                                                  _vm._f("trans")(
                                                    "form.rooming.modal.room-type.availabilities.any",
                                                    {
                                                      "%n%": _vm.availabilities(
                                                        _vm.hotel.id,
                                                        roomTypeChoice.id
                                                      ),
                                                    }
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            _vm.isRoomTypeDisabled(
                                              roomTypeChoice
                                            )
                                              ? _c("div", [
                                                  roomTypeChoice.id === 1
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            color: "#c2c0ba",
                                                          },
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              "Supplément hébergement en chambre simple : " +
                                                                _vm._s(
                                                                  _vm.supplement(
                                                                    _vm.hotel
                                                                      .id,
                                                                    roomTypeChoice.id
                                                                  )
                                                                ) +
                                                                " € TTC"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _c("div", [
                                                  roomTypeChoice.id === 1
                                                    ? _c("p", [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Supplément hébergement en chambre simple : " +
                                                              _vm._s(
                                                                _vm.supplement(
                                                                  _vm.hotel.id,
                                                                  roomTypeChoice.id
                                                                )
                                                              ) +
                                                              " € TTC"
                                                          ),
                                                        ]),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                                      " +
                                                _vm._s(
                                                  _vm._f("trans")(
                                                    "form.rooming.modal.room-type.availabilities.none"
                                                  )
                                                ) +
                                                "\n                                  "
                                            ),
                                          ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "room-submit-container" }, [
              _c(
                "button",
                {
                  staticClass: "submit c-button c-button-secondary",
                  attrs: { type: "button", disabled: _vm.isSubmitDisabled() },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("trans")("form.rooming.modal.submit")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "dispo-pdf" }, [
      _c(
        "a",
        { attrs: { onclick: "window.open('/registration/rooming/pdf');" } },
        [_vm._v("Voir\n          les\n          disponibilitées")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }